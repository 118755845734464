<template>
  <VCol class="d-flex justify-center" cols="12">
    <VBtn
      v-if="!creating"
      class="mr-2"
      color="#EB5757"
      :disabled="!deletable"
      elevation="0"
      fab
      max-height="35"
      max-width="35"
      @click="$emit('delete')"
    >
      <VImg
        contain
        height="20px"
        :src="trashIcon"
        width="20px"
      />
    </VBtn>
    <VBtn
      v-if="isChanged"
      color="#4CAF50"
      :disabled="unmodifiable"
      elevation="0"
      fab
      max-height="35"
      max-width="35"
      @click="$emit('apply')"
    >
      <VIcon color="white">{{ creating ? 'mdi-plus' : 'mdi-check' }}</VIcon>
    </VBtn>
  </VCol>
</template>

<script>
import trashIcon from '@/assets/icons/trash.svg';

export default {
  props: {
    creating: {
      type: Boolean,
      default: false,
    },
    isChanged: {
      type: Boolean,
      default: false,
    },
    deletable: {
      type: Boolean,
      default: true,
    },
    unmodifiable: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['delete', 'apply'],
  data() {
    return {
      trashIcon,
    };
  },
};
</script>
