import { render, staticRenderFns } from "./ContractCommissionEdit.vue?vue&type=template&id=67fe3d28&scoped=true"
import script from "./ContractCommissionEdit.vue?vue&type=script&lang=js"
export * from "./ContractCommissionEdit.vue?vue&type=script&lang=js"
import style0 from "./ContractCommissionEdit.vue?vue&type=style&index=0&id=67fe3d28&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "67fe3d28",
  null
  
)

export default component.exports