<template>
  <VRow class="flex-nowrap">
    <VForm
      ref="form"
      v-model="valid"
      class="d-flex"
      lazy-validation
    >
      <VCol v-for="contact in contractContactTextfield" :key="contact.key">
        <NamedRuledTextField
          class="pt-2 pb-0 px-0"
          input-width="12"
          :mask="contact.mask"
          :no-rules="contact.key === 'email'"
          :placeholder="contact.placeholder"
          regular
          solo-disabled
          :text="contactToEdit[contact.key]"
          title=""
          titleWidth="0"
          :wrapperWidth="inputWrapperWidth"
          @update="contactToEdit[contact.key] = $event"
        />
      </VCol>
    </VForm>
    <VCol class="job-title pa-0 my-auto">
      <ChipGroup
        :chips="roles"
        :mandatory="!creating"
        :preselected-value="contactToEdit.role"
        @selected="contactToEdit.role = $event"
      />
    </VCol>
    <VCol class="action">
      <RoundedButtons
        :creating="creating"
        :is-changed="isChanged"
        :unmodifiable="!valid"
        @apply="emitContact"
        @delete="emitDeletion"
      />
    </VCol>
  </VRow>
</template>

<script>
import { contractContactTextfield } from '@/conditions/textFields';
import { serializeContactRole } from '@/serializers/contractContactRoleSerializer';
import ChipGroup from '@/components/ChipGroup.vue';
import NamedRuledTextField from '@/components/NamedRuledTextField.vue';
import RoundedButtons from './RoundedButtons.vue';

export default {
  components: {
    RoundedButtons,
    NamedRuledTextField,
    ChipGroup,
  },
  props: {
    contact: {
      type: Object,
      default: () => (
        {
          name: '',
          phone: '',
          email: '',
          role: '',
        }
      ),
    },
    roles: {
      type: Array,
      default: () => [serializeContactRole()],
    },
    inputWrapperWidth: {
      type: [String, Number],
      default: 12,
    },
    buttonText: {
      type: String,
      default: 'Добавить',
    },
    creating: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      contractContactTextfield,
      contactToEdit: JSON.parse(JSON.stringify(this.contact)),
      isChanged: false,
      valid: true,
    };
  },
  methods: {
    emitDeletion() {
      this.$emit('delete', this.contactToEdit);
    },
    emitContact() {
      if (!this.$refs.form.validate()) {
        return;
      }
      const contact = this.contactToEdit;
      contact.phone = contact.phone ? contact.phone.match(/\d+/gmi).join('') : undefined;
      this.$emit('contact-event', this.contactToEdit);
      if (this.creating) {
        this.contactToEdit = {
          name: '',
          phone: '',
          email: '',
          role: '',
        };
      }
      this.isChanged = false;
    },
  },
  watch: {
    contact: {
      deep: true,
      handler() {
        this.contactToEdit = JSON.parse(JSON.stringify(this.contact));
      },
    },
    contactToEdit: {
      deep: true,
      handler() {
        if (this.contactToEdit !== this.contact) {
          this.isChanged = true;
        }
      },
    },
  },
};
</script>

<style scoped lang="scss">
.btn-wrapper {
  transition: max-height .5s, transform .5s;
}
</style>
