<template>
  <div>
    <div class="text-h5 font-weight-bold mb-5">Авансовые выплаты</div>
    <span v-if="!prepayments.length" class="subtitle-1 grey--text text--darken-1">
      {{ prepaymentsDetails }}
    </span>
    <div v-else>
      <VRow>
        <VCol class="text-center font-weight-bold grey--text text--darken-2" cols="4">
          <span>Тип аванса</span>
        </VCol>
        <VCol class="text-center font-weight-bold grey--text text--darken-2" cols="4">
          <span>Менеджер</span>
        </VCol>
        <VCol class="text-center font-weight-bold grey--text text--darken-2" cols="2">
          <span>Дата</span>
        </VCol>
        <VCol class="text-center font-weight-bold grey--text text--darken-2" cols="2">
          <span>Действие</span>
        </VCol>
      </VRow>
      <VDivider />
      <VRow
        v-for="prepayment in prepayments"
        :key="prepayment.id"
        class="body-rows"
      >
        <VCol class="d-flex align-center" cols="4">
          <span class="ml-4">{{ prepaymentTypesByKey[prepayment.type] }}</span>
        </VCol>
        <VCol class="d-flex align-center justify-center" cols="4">
          <span>{{ prepayment.username }}</span>
        </VCol>
        <VCol class="d-flex align-center justify-center" cols="2">
          <span>{{ prepayment.startedAt }}</span>
        </VCol>
        <VCol class="d-flex justify-center" cols="2">
          <span v-if="prepayment.isEditable">
            <VBtn
              icon
              @click="openDialog('edit', prepayment)"
            >
              <VIcon
                color="#6D7885"
                size="20"
                v-text="'mdi-pencil-outline'"
              />
            </VBtn>
            <VBtn
              icon
              @click="openDialog('deleteConfirmation', prepayment)"
            >
              <VIcon
                color="#C84620"
                size="20"
                v-text="'mdi-trash-can-outline'"
              />
            </VBtn>
          </span>
        </VCol>
      </VRow>
    </div>
    <div class="w-full d-flex justify-end">
      <VBtn
        class="elevation-0 py-5 br-8 mt-4 pl-auto"
        color="#E43539"
        @click="openDialog('edit', { type: '', startedAt: getMinDate() })"
      >
        <VIcon color="white" v-text="'mdi-plus'"/>
        <span class="subtitle-1 white--text mr-1">
          Добавить выплату
        </span>
      </VBtn>
      <VDialog
        v-model="dialogs.edit"
        content-class="br-8 overflow-hidden"
        max-width="720"
      >
        <VCard class="br-8">
          <div class="base-red pl-4 pr-3 py-3 d-flex align-center justify-space-between">
            <span class="white--text text-h6 font-weight-bold">
              {{ prepaymentToEdit.id ? 'Изменить' : 'Добавить' }} авансовую выплату
            </span>
            <VBtn
              icon
              small
              @click="dialogs.edit = false"
            >
              <VIcon
                color="white"
                size="22"
                v-text="'mdi-close'"
              />
            </VBtn>
          </div>
          <VRow class="px-4">
            <VCol cols="6">
              <span class="subtitle-1 font-weight-bold">Выберите тип аванса</span>
              <VSelect
                v-model="prepaymentToEdit.type"
                class="subtitle-2 pt-0"
                color="success"
                hide-details
                item-color="red"
                item-text="title"
                item-value="value"
                :items="prepaymentTypes"
                placeholder="Тип аванса"
              />
            </VCol>
            <VCol cols="6">
              <span class="subtitle-1 font-weight-bold">Дата начала</span>
              <DatePickerTextfield
                :date="prepaymentToEdit.startedAt"
                immediate
                :min="getMinDate()"
                :outlined="false"
                placeholder="Дата начала"
                @date-selected="prepaymentToEdit.startedAt = $event"
              />
            </VCol>
          </VRow>
          <div class="px-4 pt-2 pb-4 d-flex justify-end">
            <VBtn
              class="br-8"
              color="#E43539"
              outlined
              @click="dialogs.edit = false"
            >
            <span class="black--text body-2">
              Отмена
            </span>
            </VBtn>
            <VBtn
              class="elevation-0 br-8 ml-2"
              color="#E43539"
              :disabled="!prepaymentToEdit.type || !prepaymentToEdit.startedAt"
              @click="save"
            >
            <span class="white--text body-2">
              Сохранить {{ prepaymentToEdit.id ? 'изменения' : 'выплату' }}
            </span>
            </VBtn>
          </div>
        </VCard>
      </VDialog>
    </div>
    <ConfirmationDialog
      v-model="dialogs.deleteConfirmation"
      btn="Да, удалить"
      :text="
        `Будет удалена ${prepaymentTypesByKey[prepaymentToEdit.type]}
         от ${prepaymentToEdit.startedAt}.`
      "
      @cancel="dialogs.deleteConfirmation = false"
      @confirm="deletePrepayment"
    />
  </div>
</template>

<script>
import DatePickerTextfield from '@/components/DatePickerTextfield.vue';
import {
  createContractPrepaymentSchedule,
  deleteContractPrepaymentSchedule,
  getContractPrepaymentSchedules,
  updateContractPrepaymentSchedule,
} from '@/api/api';
import ConfirmationDialog from '@/components/reusable/ConfirmationDialog.vue';

export default {
  components: {
    ConfirmationDialog,
    DatePickerTextfield,
  },
  props: {
    contractId: {
      type: [Number, String],
      required: true,
    },
    prepaymentsDetails: {
      type: String,
      default: 'Данный контракт работает без авансов',
    },
  },
  data() {
    return {
      prepayments: [],
      prepaymentToEdit: {},
      prepaymentTypesByKey: {
        none: 'Без аванса',
        halfMonth: 'Внеочередная выплата',
        weekly: 'Еженедельная выплата',
      },
      dialogs: {
        edit: false,
        deleteConfirmation: false,
      },
    };
  },
  mounted() {
    this.getPrepayments();
  },
  computed: {
    prepaymentTypes() {
      return Object.entries(this.prepaymentTypesByKey).map(([value, title]) => ({ value, title }));
    },
  },
  methods: {
    getMinDate() {
      const today = new Date();
      const nextDay = new Date(today);
      nextDay.setDate(today.getDate() + 1);
      const month = nextDay.getMonth();
      const mm = String(month + 1).padStart(2, '0');
      const dd = String(nextDay.getDate()).padStart(2, '0');
      const yyyy = nextDay.getFullYear();
      return `${yyyy}-${mm}-${dd}`;
    },
    getPrepayments() {
      getContractPrepaymentSchedules(this.contractId)
        .then((prepayments) => {
          this.prepayments = prepayments;
        });
    },
    save() {
      this.prepaymentToEdit.contractId = Number(this.$route.params.id);
      if (this.prepaymentToEdit.id) {
        updateContractPrepaymentSchedule(this.prepaymentToEdit)
          .then(() => {
            this.getPrepayments();
          });
      } else {
        createContractPrepaymentSchedule(this.prepaymentToEdit)
          .then(() => {
            this.getPrepayments();
          });
      }
      this.dialogs.edit = false;
    },
    deletePrepayment() {
      deleteContractPrepaymentSchedule(this.prepaymentToEdit.id).then(() => {
        this.getPrepayments();
      });
    },
    openDialog(dialog, prepayment) {
      this.prepaymentToEdit = prepayment;
      this.dialogs[dialog] = true;
    },
  },
};
</script>

<style scoped lang="scss">
@import "src/assets/scss/main.scss";

::v-deep {
  .v-list-item--link {
    margin: 0 10px;
  }
  .v-list-item--link:before, .v-list-item--link:after {
    background-color: red;
  }
  .v-list-item--link:before, .v-list-item--link:after, .v-ripple__container {
    border-radius: 4px;
  }
  .v-list-item--link:hover::before {
    opacity: 0.08;
  }
 .v-list-item--active:hover::before, .v-list-item--active::before {
   opacity: 0;
 }
}
</style>
