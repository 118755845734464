<template>
  <VSelect
    v-model="city"
    :background-color="bgc"
    clearable
    color="success"
    :dark="dark"
    :dense="dense"
    flat
    hide-details
    item-color="success"
    item-text="title"
    item-value="title"
    :items="cities"
    label="Выберите город"
    :outlined="outlined"
    :prepend-inner-icon="disableIcon ? '' : 'mdi-city-variant-outline'"
    :rules="rules"
    :solo="solo"
  />
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  props: {
    bgc: {
      type: String,
      default: '#5B5B5B',
    },
    dark: {
      type: Boolean,
      default: true,
    },
    disableIcon: {
      type: Boolean,
      default: false,
    },
    outlined: {
      type: Boolean,
      default: true,
    },
    solo: {
      type: Boolean,
      default: true,
    },
    dense: {
      type: Boolean,
      default: true,
    },
    value: {
      type: String,
    },
    rules: {
      type: Array,
      default: () => [],
    },
  },
  emits: ['city-selected'],
  data() {
    return {
      city: this.value || '',
    };
  },
  computed: {
    ...mapGetters(['cities']),
  },
  created() {
    if (!this.cities.length) {
      this.getCities();
    }
  },
  methods: {
    ...mapActions(['getCities']),
  },
  watch: {
    city(city) {
      this.$emit('city-selected', city);
    },
    value(city) {
      this.city = city;
    },
  },
};
</script>
