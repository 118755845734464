<template>
  <tr>
    <td class="pl-4">
      {{ commission.restaurantTitle }}
    </td>
    <td>
      {{ commission.restaurantManager }}
    </td>
    <td class="text-center">
      {{ `${commission.percent}%` }}
    </td>
    <td class="text-center">
      {{ commission.dateStart }}
    </td>
    <td class="text-center">
      <VBtn
        icon
        @click="$emit('edit')"
      >
        <VIcon
          color="#6D7885"
          size="20"
          v-text="'mdi-pencil-outline'"
        />
      </VBtn>
      <VBtn
        icon
        @click="$emit('delete')"
      >
        <VIcon
          color="#C84620"
          size="20"
          v-text="'mdi-trash-can-outline'"
        />
      </VBtn>
      <VBtn
        icon
        @click="routeToRestaurant(commission.restaurantId)"
      >
        <VIcon
          color="#048848"
          size="20"
          v-text="'mdi-chevron-right'"
        />
      </VBtn>
    </td>
  </tr>
</template>

<script>
export default {
  props: {
    contractDate: {
      type: String,
      default: '',
    },
    commission: {
      type: Object,
      default: () => (
        {
          dateStart: '',
          percent: null,
          restaurantManager: '',
          restaurantTitle: '',
        }
      ),
    },
    inputWrapperWidth: {
      type: [String, Number],
      default: 12,
    },
  },
  data() {
    return {
    };
  },
  methods: {
    routeToRestaurant(restaurantId) {
      const route = this.$router.resolve({ path: `/restaurants/edit/${restaurantId}` });
      window.open(route.href, '_blank');
    },
  },
};
</script>

<style lang="scss" scoped>
tr {
  height: 64px;
}
</style>
