<template>
  <VCol
    class="my-6 white br-4 pa-6"
    cols="12"
  >
    <p class="text-h5 font-weight-bold mb-5">
      История действий
    </p>
    <UniversalTable
      class="event-log-table"
      :head-rows="contractEventLog"
    >
      <template v-slot:tr>
        <tr
          v-for="(humanizedLog, humanizedLogIndex) in humanizedLogs"
          :key="humanizedLogIndex"
        >
          <td class="pl-3">
            <span>{{ humanizedLog.username }}</span>
          </td>
          <td>
            <span>{{ translations[humanizedLog.type] }}</span>
          </td>
          <td class="text-center">
            <span>{{ humanizedLog.createdAt }}</span>
          </td>
          <td class="action-column py-2">
            <div>
              <div
                v-for="(v, index) in humanizedLog.values"
                :key="index"
                :class="{
                  'green--text': v.original === 'create',
                  'red--text': v.original === 'delete',
                  'blue--text text--accent-4': v.original === 'edit',
                  'line-through': v.unchanged,
                }"
              >
                <span
                  class="key"
                  :class="v.unchanged ? 'caption' : 'subtitle-1'"
                >
                  {{ v.key }}
                </span>
                <span
                  class="value"
                  :class="v.unchanged ? 'caption' : 'subtitle-1'"
                >
                  {{ v.value }}
                </span>
              </div>
            </div>
          </td>
        </tr>
      </template>
    </UniversalTable>
    <VCol cols="12">
      <VCol
        class="d-flex justify-end"
        cols="12"
      >
        <VBtn
          class="pa-6 br-8"
          color="#E43539"
          :disabled="currentPage <= 1"
          outlined
          @click="currentPage -= 1"
        >
          <VIcon v-text="'mdi-chevron-left'" />
          <span
            class="ml-1 subtitle-1"
            :class="{'black--text': currentPage > 1}"
          >
            Предыдущие
          </span>
        </VBtn>
        <VBtn
          class="pa-6 elevation-0 br-8 ml-6 mr-2"
          color="#E43539"
          :disabled="currentPage >= log.last_page || log.last_page == undefined"
          @click="currentPage += 1"
        >
          <span class="white--text mr-1 subtitle-1">
            Следующие
          </span>
          <VIcon
            color="white"
            size="22"
            v-text="'mdi-chevron-right'"
          />
        </VBtn>
      </VCol>
    </VCol>
  </VCol>
</template>

<script>
import UniversalTable from '@/components/UniversalTable.vue';
import { contractEventLog } from '@/conditions/tablesHeaders';
import { getContractLogs } from '@/api/api';
import { translateLogEvents } from '@/helpers/contractLog';

const translations = {
  restaurant_contract: 'Договор ресторана',
  commission: 'Комиссия',
  contact: 'Контакты',
};

export default {
  components: {
    UniversalTable,
  },
  data() {
    return {
      contractEventLog,
      translations,
      log: {},
      humanizedLogs: [],
      currentPage: 1,
    };
  },
  created() {
    this.getContractLogs();
  },
  methods: {
    getContractLogs() {
      if (this.$route.params.id) {
        getContractLogs(this.$route.params.id, this.currentPage).then((response) => {
          this.log = response;
          this.humanizedLogs = translateLogEvents(response.results);
        });
      }
    },
  },
  watch: {
    currentPage() {
      this.getContractLogs();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "src/assets/scss/main.scss";

.line-through {
  text-decoration: line-through;
}
.action-column {
  width: 400px;
}
</style>
