<template>
  <VCol cols="12">
    <div class="medium-title font-weight-bold ml-3 mt-2">
      Юридическая информация
    </div>
    <NamedRuledTextField
      v-for="textfield in textFields"
      :key="textfield.key"
      class="pt-2 pb-0 px-0 subtitle-2"
      hide-details
      :input-width="inputWidth"
      :label="textfield.label"
      :max-length="textfield.maxLength"
      :placeholder="textfield.placeholder"
      regular
      solo-disabled
      :text="contract[textfield.key]"
      title-width="0"
      :wrapper-width="inputWrapperWidth"
      @update="contract[textfield.key] = $event"
    />
    <VCombobox
      v-model="contract.manager"
      class="px-3"
      color="success"
      hide-details
      hide-no-data
      hide-selected
      :items="managers"
      label="Менеджер"
      persistent-hint
      placeholder="email менеджера"
      :rules="rules"
      :value="contract.manager"
      @update:search-input="findManager"
    />
    <VCol :cols="inputWrapperWidth">
      <DatePickerTextfield
        :date="contract.date"
        label="Дата договора"
        :outlined="false"
        :rules="rules"
        @date-selected="setDate"
      />
    </VCol>
    <VCol
      class="py-0 px-2"
      cols="12"
    >
      <VTextarea
        v-model="contract.description"
        clearable
        color="success"
        no-resize
        outlined
        placeholder="Полное описание"
      />
    </VCol>
    <VCol
      class="py-0 px-2"
      :cols="inputWrapperWidth"
    >
      <CitySelector
        bgc="white"
        :dark="false"
        :dense="false"
        :outlined="false"
        :rules="rules"
        :solo="false"
        :value="contract.city"
        @city-selected="contract.city = $event"
      />
    </VCol>
  </VCol>
</template>

<script>
import NamedRuledTextField from '@/components/NamedRuledTextField.vue';
import DatePickerTextfield from '@/components/DatePickerTextfield.vue';
import CitySelector from '@/components/CitySelector.vue';
import { contractTextfields } from '@/conditions/textFields';

export default {
  components: {
    DatePickerTextfield,
    NamedRuledTextField,
    CitySelector,
  },
  props: {
    inputWrapperWidth: {
      type: [String, Number],
      default: 10,
    },
    inputWidth: {
      type: [String, Number],
      default: 10,
    },
    partnerContract: {
      type: Object,
      required: true,
    },
    creating: {
      type: Boolean,
      default: false,
    },
    managers: {
      type: Array,
      default: () => [],
    },
  },
  emits: ['search-manager'],
  data() {
    return {
      contract: this.partnerContract,
      textFields: contractTextfields,
      rules: [(v) => !!v || 'Обязательно поле'],
    };
  },
  methods: {
    setDate(date) {
      this.contract.date = date;
    },
    findManager(managerEmail) {
      if (managerEmail && managerEmail.length >= 3) {
        this.$emit('search-manager', managerEmail);
      }
    },
  },
  watch: {
    partnerContract: {
      deep: true,
      handler() {
        this.contract = this.partnerContract;
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.medium-title {
  font-size: 24px;
  line-height: 38px;
}
</style>
