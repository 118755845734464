<template>
  <VCard class="br-8">
    <div class="d-flex justify-space-between align-center base-red pl-4 pr-3 py-3">
      <span class="subtitle-1 font-weight-bold white--text">
        {{ isEditing ? 'Изменить' : 'Добавить' }} комиссию
      </span>
      <VBtn
        color="white"
        icon
        small
        @click="close"
      >
        <VIcon v-text="'mdi-close'" />
      </VBtn>
    </div>
    <VForm
      ref="form"
      v-model="valid"
      class="pa-4"
      lazy-validation
    >
      <RestaurantSelection
        outline-color="success"
        :readonly="isEditing"
        required
        :restaurant="selectedRestaurant"
        @restaurantSelected="selectedRestaurant = $event"
      />
      <VRow>
        <VCol class="pt-0" cols="6">
          <VTextField
            v-model="commissionToEdit.percent"
            v-mask="'##,##'"
            class="subtitle-2 pt-0"
            color="success"
            label="Процент"
            :rules="rules"
          />
        </VCol>
        <VCol class="pt-0" cols="6">
          <DatePickerTextfield
            :date="commissionToEdit.dateStart"
            label="Дата"
            :outlined="false"
            :rules="rules"
            @date-selected="commissionToEdit.dateStart = $event"
          />
        </VCol>
      </VRow>
      <div class="full-width d-flex justify-end">
        <VBtn
          class="br-8"
          color="#E43539"
          outlined
          @click="close"
        >
          <span class="black--text body-2">
            Отмена
          </span>
        </VBtn>
        <VBtn
          class="elevation-0 br-8 ml-2"
          color="#E43539"
          :disabled="noChanges || !valid"
          @click="validate"
        >
          <span class="white--text body-2">
            {{ isEditing ? 'Сохранить изменения' : 'Добавить' }}
          </span>
        </VBtn>
        <VDialog
          v-model="showCreateConfirmation"
          content-class="br-8 overflow-hidden"
          max-width="456"
        >
          <VCard class="br-8">
            <div class="base-red subtitle-1 font-weight-bold white--text pl-4 pr-3 py-3">
              Подтвердить действие
            </div>
            <div class="pa-4">
              <div class="mb-6 body-2">
                {{ `Вы подтверждаете добавление комиссии “${commissionToEdit.percent}”
                 для ресторана “${selectedRestaurant ? selectedRestaurant.title : ''}”?` }}
              </div>
              <div class="full-width d-flex justify-end">
                <VBtn
                  class="br-8"
                  color="#E43539"
                  outlined
                  @click="showCreateConfirmation = false"
                >
                  <span class="black--text body-2">
                    Вернуться назад
                  </span>
                </VBtn>
                <VBtn
                  class="elevation-0 br-8 ml-2"
                  color="#E43539"
                  @click="createCommission"
                >
                  <span class="white--text body-2">
                    Да, подтвердить
                  </span>
                </VBtn>
              </div>
            </div>
          </VCard>
        </VDialog>
      </div>
    </VForm>
  </VCard>
</template>

<script>
import RestaurantSelection from '@/components/RestaurantSelection.vue';
import DatePickerTextfield from '@/components/DatePickerTextfield.vue';
import { createContractCommission, updateContractCommission } from '@/api/api';

export default {
  components: { DatePickerTextfield, RestaurantSelection },
  emits: ['close', 'update'],
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      commissionToEdit: {
        percent: '',
        dateStart: '',
        restaurantId: null,
      },
      selectedRestaurant: {},
      showCreateConfirmation: false,
      rules: [(value) => !!value || 'Поле не заполнено'],
      isEditing: false,
      valid: false,
    };
  },
  created() {
    if (this.value.id) {
      this.isEditing = true;
      this.commissionToEdit = { ...this.value };
      this.selectedRestaurant = {
        pk: this.value.restaurantId,
        title: this.value.restaurantTitle,
      };
    }
  },
  computed: {
    noChanges() {
      return this.commissionToEdit.percent === this.value.percent
          && this.commissionToEdit.dateStart === this.value.dateStart;
    },
  },
  methods: {
    validate() {
      if (this.$refs.form.validate()) {
        if (this.isEditing) {
          this.commissionToEdit.restaurantId = Number(this.selectedRestaurant.pk);
          updateContractCommission(this.commissionToEdit)
            .then(() => {
              this.update();
            });
          this.close();
        } else {
          this.showCreateConfirmation = true;
        }
      }
    },
    createCommission() {
      const contractId = this.$route.params.id;
      this.commissionToEdit.restaurantId = Number(this.selectedRestaurant.pk);
      createContractCommission(contractId, this.commissionToEdit).then(() => {
        this.update();
      }).finally(() => {
        this.showCreateConfirmation = false;
        this.close();
      });
    },
    close() {
      this.$emit('close');
    },
    update() {
      this.$emit('update');
    },
  },
};
</script>

<style lang="scss" scoped>
@import "src/assets/scss/main.scss";

::v-deep {
  .v-icon {
    font-size: 20px;
  }
}
</style>
