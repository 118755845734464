function serializeTextFields(placeholder, label, key, mask, maxLength) {
  return {
    placeholder,
    label,
    key,
    mask,
    maxLength,
  };
}

export const restaurantPhoneTextfield = [
  serializeTextFields('Имя', 'Введите имя', 'title'),
  serializeTextFields('+7(7хх)ххх-хх-хх', 'Номер телефона', 'number', '+7 (7##) ###-##-##'),
];

export const contractTextfields = [
  serializeTextFields('Название', 'Название', 'title'),
  serializeTextFields('№ контракта', 'Номер контракта', 'number'),
  serializeTextFields('Организация', 'Юр.лицо', 'organizationName'),
  serializeTextFields('Где находятся?', 'Факт.адрес', 'address'),
  serializeTextFields('Официальный адрес', 'Юр.адрес', 'legalAddress'),
  serializeTextFields('e-mail', 'Почта', 'email'),
  serializeTextFields('БИН', 'БИН', 'bin', undefined, 12),
  serializeTextFields('БИК', 'БИК', 'bic', undefined, 8),
  serializeTextFields('Банк', 'Банк', 'bankName'),
  serializeTextFields('Счёт', 'Банковский счёт', 'bankAccount', undefined, 32),
];

export const contractContactTextfield = [
  serializeTextFields('Имя', 'Введите имя', 'name'),
  serializeTextFields('+7(7хх)ххх-хх-хх', 'Номер телефона', 'phone', '+7(7##) ###-##-##'),
  serializeTextFields('Введите e-mail', 'E-mail для связи', 'email'),
];

export const contractCommissionTextfield = [
  serializeTextFields('Размер комиссии в %', 'Введите размер комиссии в целых числах', 'percent'),
  serializeTextFields('Сумма', 'Введите сумму', 'amount'),
];
