<template>
  <VChipGroup
    v-model="value"
    class="w100"
    color="white"
    column
    :mandatory="mandatory"
    max="1"
    :value="value"
  >
    <VCol
      v-for="chip in chips"
      :key="chip.value"
      :cols="Math.floor(12 / chips.length)"
    >
      <VChip
        class="w100 d-flex justify-center chip py-5"
        :class="value === chip.value ? 'green text--white' : 'grey lighten-4 text--black'"
        label
        :value="chip.value"
      >
        {{ chip.text }}
      </VChip>
    </VCol>
  </VChipGroup>
</template>

<script>
export default {
  props: {
    chips: {
      type: Array,
      default: () => [],
    },
    preselectedValue: {
      type: [String, Boolean, Number],
    },
    mandatory: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['selected'],
  data() {
    return {
      value: this.preselectedValue,
    };
  },
  watch: {
    preselectedValue(newValue) {
      if (newValue !== this.value) {
        this.value = newValue;
      }
    },
    value(selectedValue) {
      this.$emit('selected', selectedValue);
    },
  },
};
</script>

<style lang="scss" scoped>

.w100 {
  width: 100%;
}

.chip {
  border-radius: 10px !important;
}
</style>
