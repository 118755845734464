/* eslint-disable camelcase */
import { dateFromBackend } from '@/helpers/date';

function createValue(key, value, original) {
  return {
    key,
    value,
    original,
  };
}

class DataTranslation {
  constructor(log) {
    this.type = log.type;
    this.logId = log.id;
    this.difference = log.difference;
    this.username = log.username;
    this.createdAt = dateFromBackend(log.created_dt, 'YYYY-MM-DD HH:mm');
    this.values = [];
  }

  id(value) {
    this.values.push(createValue('id', value, 'id'));
    return this;
  }

  name(value) {
    this.values.push(createValue('Имя', value, 'name'));
    return this;
  }

  role(value) {
    const rolesFromTo = value.split(/\W+/);
    const translatedValue = rolesFromTo.map(
      (role) => (role === 'director' ? 'Директор' : 'Бухгалтер'),
    ).join(' → ');
    this.values.push(createValue('Должность', translatedValue, 'role'));
    return this;
  }

  email(value) {
    this.values.push(createValue('email', value, 'email'));
    return this;
  }

  phone(value) {
    this.values.push(createValue('Телефон', value, 'email'));
    return this;
  }

  created_dt(value) {
    this.values.push(createValue('Дата создания', dateFromBackend(value, 'YYYY-MM-DD HH-mm'), 'created_dt'));
    return this;
  }

  date(value) {
    this.values.push(createValue('Дата', value, 'date'));
    return this;
  }

  amount(value) {
    this.values.push(createValue('Количество', value, 'amount'));
    return this;
  }

  percent(value) {
    this.values.push(createValue('Процент', value, 'percent'));
    return this;
  }

  is_deleted(value) {
    const bool = typeof value === 'string' ? JSON.parse(value.split(' → ')[1]) : value;
    this.values.push(createValue('Удален', bool ? 'Да' : 'Нет', 'is_deleted'));
    return this;
  }

  is_default(value) {
    this.values.push(createValue('По умолчанию', value ? 'Да' : 'Нет', 'is_default'));
    return this;
  }

  contract_id(value) {
    this.values.push(createValue('Id контракта', value, 'contract_id'));
    return this;
  }

  restaurant_id(value) {
    this.values.push(createValue('Id ресторана', value, 'restaurant_id'));
    return this;
  }

  bic(value) {
    this.values.push(createValue('БИК', value, 'bic'));
    return this;
  }

  bin(value) {
    this.values.push(createValue('БИН', value, 'bin'));
    return this;
  }

  city(value) {
    this.values.push(createValue('Город', value, 'city'));
    return this;
  }

  title(value) {
    this.values.push(createValue('Название', value, 'title'));
    return this;
  }

  number(value) {
    this.values.push(createValue('Номер договора', value, 'number'));
    return this;
  }

  address(value) {
    this.values.push(createValue('Адрес', value, 'address'));
    return this;
  }

  approved(value) {
    this.values.push(createValue('Подтвержден', value ? 'да' : 'нет', 'approved'));
    return this;
  }

  bank_name(value) {
    this.values.push(createValue('Банк', value, 'bank_name'));
    return this;
  }

  description(value) {
    this.values.push(createValue('Описание', value, 'description'));
    return this;
  }

  bank_account(value) {
    this.values.push(createValue('Банковский счёт', value, 'bank_account'));
    return this;
  }

  legal_address(value) {
    this.values.push(createValue('Юр.адрес', value, 'legal_address'));
    return this;
  }

  organization_name(value) {
    this.values.push(createValue('Название организации', value, 'organization_name'));
    return this;
  }

  short_description(value) {
    this.values.push(createValue('Краткое описание', value, 'short_description'));
    return this;
  }
}

export const translateLogEvents = (responseResults) => {
  const events = [];
  responseResults.forEach((event) => {
    const build = new DataTranslation(event);
    if (!event.old_data) {
      build.values.push(createValue('Событие:', 'создание', 'create'));
    } else if (event.difference && event.difference.is_deleted) {
      build.values.push(createValue('Событие:', 'удаление', 'delete'));
    } else {
      build.values.push(createValue('Событие:', 'редактирование', 'edit'));
    }
    Object.keys(event.new_data)
      .forEach((key) => {
        if (build[key]) {
          if (event.difference && event.difference[key]) {
            build[key](
              `${event.difference[key].old_value} → ${event.difference[key].new_value}`,
            );
          } else {
            build[key](event.old_data[key]);
            build.values[build.values.length - 1].unchanged = true;
          }
        }
      });
    events.push(build);
  });
  return events;
};
