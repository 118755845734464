<template>
  <VDialog
    v-model="showDialog"
    content-class="br-8 overflow-hidden"
    :disabled="!text"
    max-width="500"
  >
    <VCard class="br-8">
      <div class="pl-4 pr-3 py-3 base-red d-flex justify-space-between align-center">
        <div class="subtitle-1 white--text font-weight-bold">
          Вы уверены?
        </div>
        <VBtn
          icon
          small
          @click="showDialog = false"
        >
          <VIcon
            color="white"
            size="22"
            v-text="'mdi-close'"
          />
        </VBtn>
      </div>
      <div class="pa-4">
        {{ text }}
      </div>
      <div class="d-flex justify-end pa-4">
        <VBtn
          class="br-8 mr-2"
          color="#E43539"
          outlined
          @click="cancel"
        >
          <span class="black--text body-2">Нет, отмена</span>
        </VBtn>
        <VBtn
          class="br-8 elevation-0 body-2"
          color="#E43539"
          @click="confirm"
        >
          <span class="white--text">{{ btn }}</span>
        </VBtn>
      </div>
    </VCard>
  </VDialog>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    text: {
      type: String,
      default: '',
    },
    btn: {
      type: String,
      default: 'Да, потдвердить',
    },
  },
  emits: ['input', 'confirm', 'cancel'],
  data() {
    return {
      showDialog: false,
    };
  },
  mounted() {
    this.showDialog = this.value;
  },
  methods: {
    confirm() {
      this.$emit('confirm');
      this.showDialog = false;
    },
    cancel() {
      this.showDialog = false;
      this.$emit('cancel');
    },
  },
  watch: {
    value() {
      this.showDialog = this.value;
    },
    showDialog() {
      this.$emit('input', this.showDialog);
    },
  },
};
</script>

<style scoped lang="scss">
@import "src/assets/scss/main.scss";

</style>
