<template>
  <VMenu
    ref="menu"
    v-model="appearance"
    :close-on-content-click="false"
    min-width="auto"
    offset-y
    :return-value.sync="editedDate"
    transition="scale-transition"
  >
    <template v-slot:activator="{ on, attrs }">
      <VTextField
        v-model="editedDate"
        v-bind="attrs"
        append-icon="mdi-calendar-month-outline"
        class="subtitle-2 pt-0"
        color="success"
        hide-details
        :label="label"
        :outlined="outlined"
        :placeholder="placeholder"
        readonly
        :rules="rules"
        v-on="on"
      />
    </template>
    <VDatePicker
      v-model="editedDate"
      class="pt-1"
      color="#E43539"
      event-color="#E43539"
      locale="ru-KZ"
      :max="max"
      :min="min"
      no-title
      scrollable
    >
      <VSpacer/>
      <span v-if="!immediate" class="mb-2">
        <VBtn
          class="text-none br-8 pr-4 mr-2"
          color="#E43539"
          outlined
          @click="appearance = false"
        >
          <VIcon
            color="black"
            size="20"
            v-text="'mdi-close'"
          />
          <span class="black--text body-2">Закрыть</span>
        </VBtn>
        <VBtn
          class="text-none br-8 pr-4 elevation-0 mr-2"
          color="#E43539"
          @click="save(editedDate)"
        >
          <VIcon
            color="white"
            size="20"
            v-text="'mdi-check'"
          />
          <span class="white--text body-2">Сохранить</span>
        </VBtn>
      </span>
    </VDatePicker>
  </VMenu>
</template>

<script>

export default {
  props: {
    date: {
      type: String,
      default: '',
    },
    outlined: {
      type: Boolean,
      default: true,
    },
    label: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    min: {
      type: String,
      default: '',
    },
    max: {
      type: String,
      default: '',
    },
    immediate: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: Array,
      default: () => [],
    },
  },
  emits: ['date-selected'],
  data() {
    return {
      appearance: false,
      editedDate: '',
    };
  },
  created() {
    this.editedDate = this.date;
    this.emit();
  },
  methods: {
    emit() {
      this.$emit('date-selected', this.editedDate);
    },
    save() {
      this.$refs.menu.save(this.editedDate);
      this.emit();
    },
  },
  watch: {
    date(newDate) {
      this.editedDate = newDate;
    },
    appearance(show) {
      if (this.immediate && !show) {
        this.save();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "src/assets/scss/main.scss";

</style>
